import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'
import OfferPostItem from '../components/offer-post-item'
import Breadcrumbs from '../components/breadcrumbs'

const OffersLayoutContainer = styled.div`
  display: flex;
  padding: 1em 0em; /* Match padding to blog.js */
  flex-wrap: wrap;
`;

// Helper function to calculate the last date of the current month
const getLastDateOfCurrentMonth = () => {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth() + 1, 0).toLocaleDateString('en-US');
}

const OffersPage = (props) => (
  <StaticQuery
    query={graphql`
      query GetAllOffers {
        allContentfulOffer {
          edges {
            node {
              id
              title
              slug
              excerpt {
                excerpt
              }
              image {
                gatsbyImageData(width: 400, quality: 70, placeholder: NONE)
              }
              notificationBarActionText
              offerCode
            }
          }
        }
      }
    `}
    render={(data) => {
      const lastDateOfCurrentMonth = getLastDateOfCurrentMonth(); // Use the helper function
      return (
        <Layout pageProps={props}>
          <div className="bg-white border-b border-gray-100">
            <nav
              className="flex max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-3"
              aria-label="Breadcrumb"
            >
              <Breadcrumbs
                links={[
                  { to: '/', label: 'Home' },
                  {
                    to: props.location.pathname,
                    label: `Offers`,
                  },
                ]}
              />
            </nav>
          </div>
          <SEO
            title={'Bay Area Siding Company Offers & Discounts'}
            description={
              "Take full advantage of our exclusive deals and promotions to save big on your next siding, windows, or exterior home improvement project with Bay Area Siding Company!"
            }
          />
          <div className="px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
            <div className="py-10">
              <h1 className="font-display font-bold text-2xl md:text-5xl">Bay Area Siding Company Offers</h1>
              <p className="mt-4 text-lg text-gray-600">
              Don’t miss your chance to transform your home and save big on your next siding or window upgrade—elevate your curb appeal and energy efficiency today!







              </p>
            </div>

            <OffersLayoutContainer>
              {data.allContentfulOffer.edges.map((item) => (
                <div key={item.node.id} className="px-4 sm:w-1/2 lg:w-1/3">
                  <Link to={item.node.slug} className="block">
                    <OfferPostItem
                      title={item.node.title}
                      slug={item.node.slug}
                      excerpt={item.node.excerpt.excerpt}
                      expiresOn={lastDateOfCurrentMonth} // Ensure lastDateOfCurrentMonth is passed
                      image={item.node.image}
                      notificationBarActionText={item.node.notificationBarActionText}
                      offerCode={item.node.offerCode}
                    />
                  </Link>
                </div>
              ))}
            </OffersLayoutContainer>
          </div>
        </Layout>
      )
    }}
  />
)

export default OffersPage
